import React from 'react';
import Header from '../../../Header';
import s from './style.scss';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import COLUMNS from './COLUMNS';
import { Row, Col } from 'react-bootstrap';
import CaretLeft from '../../../../assets/CaretLeft.svg';
import { Link } from 'react-router-dom';

const DesignProfessionals = () => {
  const data = [
    {
      firmName: 'Cianci Engineering',
      yearlyGoalCompletion: '75%',
      designProfessionals: 14,
      documentsCount: 60,
    },
    {
      firmName: 'Smith Engineering',
      yearlyGoalCompletion: '45%',
      designProfessionals: 15,
      documentsCount: 61,
    },
    {
      firmName: 'Engineering Bay',
      yearlyGoalCompletion: '54%',
      designProfessionals: 16,
      documentsCount: 62,
    },
    {
      firmName: 'Baron Engineering',
      yearlyGoalCompletion: '39%',
      designProfessionals: 17,
      documentsCount: 63,
    },
    {
      firmName: 'Sterling Engineering',
      yearlyGoalCompletion: '79%',
      designProfessionals: 18,
      documentsCount: 64,
    },
    {
      firmName: 'Hilton Engineering',
      yearlyGoalCompletion: '98%',
      designProfessionals: 19,
      documentsCount: 65,
    },
    {
      firmName: 'Baron Engineering',
      yearlyGoalCompletion: '47%',
      designProfessionals: 20,
      documentsCount: 66,
    },
  ];

  return (
    <>
      <Header title="Edge Portal" edgePortal />
      <div className={s.wrapper}>
        <Row style={{ marginBottom: '10px' }}>
          <Link to="/firmDetails" style={{ textDecoration: 'none' }}>
            <div className={s.backContainer}>
              <img src={CaretLeft} />
              <span style={{ color: 'black' }}>
                Cianci Engineering’s Yearly Goal Completion Insights
              </span>
            </div>
          </Link>
        </Row>
        <Row>
          <Col></Col>
          <Col xs={12} sm={12} md={10} lg={10}>
            <ReactTable
              data={data}
              columns={COLUMNS}
              expanded={true}
              className={s.ReactTable}
              //   getTrProps={getTrProps}
              showPagination={true}
              // loading={appEasyState.tableLoading}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default DesignProfessionals;
