import React, { useState, Fragment, useEffect } from 'react';
import s from './style.scss';
import s1 from './new_style.scss';

import { Formik, Form } from 'formik';
import { Form as BForm, Button, Container, Row, Col, Card } from 'react-bootstrap';
import LogoBrand from '../../../LogoBrand';
import { withFirebase } from '../../../../auth/firebase';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../utils/constants';
import { view } from 'react-easy-state';
import {appEasyState} from '../../../../easyStateStore';
import OTPVerification from '../../Onboarding/verification';
import CareLeft from '../../../../assets/CaretLeft.svg';


const MobileAuth = (props) => {
  const { user, firebase } = props;
  const {
    history: { push },
  } = props;

  const { currentUser } = props.firebase.auth;

  const [loading, setLoading] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [verificationCode, setVerificationCode] = useState('')

  const sendVerificationCode = (phoneNumber) => {
    const appVerifier = window.recaptchaVerifier;
    props.firebase.auth
      .signInWithPhoneNumber(phoneNumber, appVerifier)
      .then((confirmFunction) => {
        window.confirmFunction = confirmFunction;
        // store.addNotification({
        //   title: 'Code Sent Successfully',
        //   message: 'Please check your mobile number for the code.',
        //   type: 'default',
        //   ...NOTIFICATION_PROPS,
        // });
      })
      .catch((error) => {
        if (!error.message.includes('client element has been removed')) {
          store.addNotification({
            title: 'Error',
            message: error.message,
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
        }
      });
  };

  let recaptchaNode, resendRecaptchaNode
  useEffect(() => {
    if (props && !captchaVerified) {
      window.recaptchaVerifier = new props.firebase.RecaptchaVerifier(
        recaptchaNode,
        {
          size: 'normal',
          callback: () => setCaptchaVerified(true),
        },
      );
      window.recaptchaVerifier.render().then(function (widgetId) {
        window.recaptchaWidgetId = widgetId;
        console.log('capchaVerified Id:', widgetId)
      });

      const phoneNumber = appEasyState.login.phoneNumber.length > 11
        ? '' + appEasyState.login.phoneNumber
        : appEasyState.login.phoneNumber.length === 11
          ? '+' + appEasyState.login.phoneNumber
          : '+1' + appEasyState.login.phoneNumber

      sendVerificationCode(phoneNumber);
    }
  }, [captchaVerified])


  const verifySMScode = (code) => {
    if (!code) {
      store.addNotification({
        title: 'Error',
        message: 'Please enter the verification code.',
        type: 'danger',
        ...NOTIFICATION_PROPS,
      });
      return;
    }

    setLoading(true)

    window.confirmFunction
      .confirm(code)
      .then(async (res) => {
        console.log("window.location.href", window.location.href)
        await props.firebase
          .doSignInWithEmailAndPassword(appEasyState.login.email, appEasyState.login.password)
          .then((res) => {
            localStorage.setItem('userId', res.user.uid)
            console.log('**** res: ', res)

            localStorage.setItem('authUser', res.user)
            appEasyState.user = res.user;
            appEasyState.firebase = props.firebase;

            appEasyState.userFirstName = res.user.firstName || null
            appEasyState.userImage = res.user.image || undefined

            localStorage.setItem('encryptedString', appEasyState.getEncryptedString(res.user.uid, 'sealpact-origami/crewlogix'))
            localStorage.setItem('isFreshLogin', 'false')
            setTimeout(() => {
              setLoading(false);
              window.location.href = '/firmDetails'
            }, 1500)
            if (!res.user.emailVerified) {
              setTimeout(() => {
                push('/emailVerification')
              }, 2500)
            }

            return;
          })
          .catch(async (error) => {
            setLoading(false);
            store.addNotification({
              title: 'Error',
              message: error.message,
              type: 'danger',
              ...NOTIFICATION_PROPS,
            });
            console.log('mobile auth catch 1', error)
            await props.firebase.doSignOut()
              .then((signoutRes) => {
                localStorage.setItem('encryptedString', 'null')
                localStorage.setItem('userId', 'null')
                setTimeout(() => {
                  window.location.href = '/login'
                }, 4000)
                return;
              })
              .catch((error) => {
                console.log('mobile auth catch 2',error);
              });
          });
      })
      .catch((err) => {
        setLoading(false);
        store.addNotification({
          title: 'Error',
          message: 'The provided SMS verification code is invalid.',
          type: 'danger',
          ...NOTIFICATION_PROPS,
        });
      });
  };

  return  (
    <Fragment>
      <Formik
        initialValues={{ verificationCode: '' }}
        initialErrors={{ verificationCode: 'Not Valid' }}
      >
        {(formikProps) => {
          debugger;
          const { handleSubmit, errors, isSubmitting, handleChange } =
            formikProps;
          const hasErrors = Object.keys(errors).length > 0;
          return (
            <Form onSubmit={handleSubmit} className={s1.backgroundImage}>
              <Container className={s1.wrapper}>
                <div className={s1.headerLogo}>
                  <LogoBrand imgSize={48} className={s1.logoStyle} {...props} />
                </div>
                <div className={s1.centerClass}>
                  <div className={`${s1.marginForm}`}>
                    <div id="Login" className="d-flex justify-content-center align-items-center">
                      <Card className={s1.onboardingCard}>
                        <div className={s1.wrapper}>
                          <Card.Title>
                            <div className={'d-flex flex-direction-row'}>
                              <img
                                src={CareLeft}
                                className={s1.arrowImage}
                                alt='Arrow'
                                onClick={async () => {
                                  // console.log('formik values', formikProps.values)
                                  await props.firebase.doSignOut()
                                    .then((signoutRes) => {
                                      localStorage.setItem('encryptedString', 'null')
                                      localStorage.setItem('userId', 'null')
                                      // push('/login');
                                      setCaptchaVerified(false)
                                      props.setLoginCardCompleted(false);

                                    })
                                    .catch((error) => {
                                      console.log(error);
                                    });

                                }}
                              />
                              <span className={s1.cardTitle}>OTP Verification</span>
                            </div>
                          </Card.Title>
                          <Card.Subtitle className={s1.cardSubtitle}>
                            {!captchaVerified ? (
                              'Please verify the CAPTCHA for a code to be sent to your mobile number'
                            ) : (
                              'Please enter the 6 digit verification code that we have sent to your mobile number'
                            )}
                          </Card.Subtitle>
                          <Card.Body style={{ padding: '0' }}>
                            <Row>
                              <Col>
                                <div>
                                  {!captchaVerified ? (
                                    <center
                                      style={{ marginTop: '32px', minHeight: '80px' }}
                                      ref={(ref) => (recaptchaNode = ref)}>
                                    </center>
                                  ) : (
                                    <>
                                      <OTPVerification
                                        setVerificationCode={setVerificationCode}
                                        verifySMScode={verifySMScode}
                                      />
                                      <center>
                                        If you didn't receive the code!&nbsp;
                                        <b
                                          style={{ cursor: 'pointer', color: '#04B5FF' }}
                                          onClick={async () => {
                                            setCaptchaVerified(false)
                                          }}
                                        >
                                          Resend
                                        </b>
                                      </center>
                                    </>
                                  )}
                                  <Button
                                    variant="primary"
                                    disabled={verificationCode.length !== 6}
                                    // type="submit"
                                    className={s.button}
                                    block
                                    // onClick={() => verifySMScode(verificationCode)}
                                  >
                                    {loading ? (
                                      <div>
                                        <span className="spinner-border spinner-border-sm mr-2" />
                                        Verifying
                                      </div>
                                    ) : (
                                      <div>
                                        Verify
                                      </div>
                                    )}
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                          </Card.Body>
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </Container>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
};

export default withFirebase(view(MobileAuth));
