import React, { useState, Fragment, useEffect } from 'react';
import Login from '../Login';
import MobileAuth from './../MobileAuth';
import { Formik, Form } from 'formik';
import {
  Form as BForm,
  Button,
  Container,
  Row,
  Col,
  Card,
} from 'react-bootstrap';
import { LOGINVALIDATION } from '../../../../config/FormSchemas';
import { withFirebase } from '../../../../auth/firebase';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../../utils/constants';
import { appEasyState } from '../../../../easyStateStore';
import Spinner from '../../../Layout/Spinner';

const LoginAndVerification = (props) => {
  const [loginCardCompleted, setLoginCardCompleted] = useState(false);

  const {
    history: { push },
  } = props;

  const { currentUser } = props.firebase.auth;

  useEffect(() => {
    if (currentUser && currentUser.email)
      localStorage.setItem('userId', currentUser.uid);

    const subscriptionRedirected =
      localStorage.getItem('isFirstSubscriptionRedirection') || 'null';
    if (subscriptionRedirected === 'true') {
      localStorage.setItem('isFreshLogin', 'false');
      localStorage.setItem('isFirstSubscriptionRedirection', 'true');
      // window.location.href = '/subscription';
    } else if (currentUser && currentUser.email) {
      // alert('in login else if')
      console.log('currentUser.email======>>>>', currentUser.email);
      localStorage.setItem('isFreshLogin', 'false');
      // window.location.href = '/dashboard'
    } else localStorage.setItem('isFreshLogin', 'true');
  }, []);

  return (
    <div>
      {!loginCardCompleted ? (
        <Login setLoginCardCompleted={setLoginCardCompleted} {...props} />
      ) : (
        <MobileAuth setLoginCardCompleted={setLoginCardCompleted} {...props} />
      )}
    </div>
  );
};

export default withFirebase(LoginAndVerification);
