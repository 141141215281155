import React, { useEffect, useRef } from "react";
import { Doughnut, Pie, Bar } from 'react-chartjs-2';
import s from './style.scss';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  } from 'chart.js';

  // Register the required components
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
  );
const BarChart = ({ data}) => {
    // Chart options including the custom plugin
    const options = {
      responsive: true, // Set the chart to be responsive
      maintainAspectRatio: false, // Allow the chart to stretch as needed
      cutout: '50%', // Adjust the width of the doughnut
      plugins: {
        legend: {
          display: false,
        },
      },
      scales: {
        x: {
          grid: {
            display: false, // Hides the vertical grid lines
          },
        },
        y: {
          grid: {
            display: false, // Hides the horizontal grid lines
          },
          ticks: {
            stepSize: 100,
          }
        },
      }
    };

    return (
      <div style={{ height: '300px' }}>
        <Bar data={data} options={options} />
        {/* Render the overlay div for the subtitle */}
      </div>
    );
  };

const ProjFeeAnalytics=()=>{
    useEffect(()=>{
        console.log('Baar chart',Doughnut, Bar)
    },[])
    const labelsData=[
        {
            label:'$0 - $1000',
            color:'#97B4FF',
            data:450,
        },
        {
            label:'$1001 - $2000',
            color:'#477BFF',
            data:487,
        },
        {
            label:'$2001 - $3000',
            color:'#97B4FF',
            data:420,
        },
        {
            label:'$3001 - $4000',
            color:'#97B4FF',
            data:250,
        },
        {
            label:'$4001 - $5000',
            color:'#97B4FF',
            data:350,
        },
    ]
    const chartData1 = {
        labels: labelsData.map(l=>l.label),
        // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
        datasets: [
          {
            data: labelsData.map(l =>l.data),
            // you can set indiviual colors for each bar
            backgroundColor: labelsData.map(l => l.color),
            borderRadius:8,
            barPercentage:1
          },
        ]
    }
    const chartRef=useRef()
    return (
        <div className={s.statisticsCard}>
            <p className={s.headingText}>Project Fee Range</p>
            <BarChart
            data={chartData1}
            />
        </div>
    )
}
export default ProjFeeAnalytics;