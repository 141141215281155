import React, { Fragment, useEffect } from 'react';
import Routes from '../../routes';
import withAuthentication from '../../auth/session/withAuthentication';
import ReactNotification from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import { useIdleTimer } from 'react-idle-timer';

const App = (props) => {
  // console.log('props ==> ', props);

  const handleOnIdle = (event) => {
    props.firebase
      .doSignOut()
      .then((signoutRes) => {
        localStorage.setItem('encryptedString', 'null')
        localStorage.setItem('userId', 'null')
        // localStorage.setItem('isFreshLogin', 'true')
        return;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const checkAuth = () => {
    const userId = localStorage.getItem('userId') || "null"
    console.log('check auth is called:',userId)
    return userId !== "null"
  }
  // logout on tab close
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "hidden") {
        // Store the timestamp when the tab becomes hidden
        sessionStorage.setItem("lastUnloadTime", Date.now().toString());
      }
    };

    const handlePageLoad = () => {

        // Set a flag in sessionStorage to indicate a reload is happening
        const lastUnloadTime = sessionStorage.getItem("lastUnloadTime");
        if (lastUnloadTime) {
          const timeDifference = Date.now() - parseInt(lastUnloadTime, 10);

          // Treat as a reload if time difference is within the threshold
          if (timeDifference >1000) {
          } else {
            if (checkAuth()) {
              autoLogout();
            }
          }
        } else {
          if (window.location.pathname !== "/forgotPassword/" && checkAuth()) {
            autoLogout();
          }
        }

        // Clear the unload time to avoid stale data
        sessionStorage.removeItem("lastUnloadTime");
      };

      // Check the unload time on page load
      handlePageLoad();

      // Listen to visibility change events
      document.addEventListener("visibilitychange", handleVisibilityChange);

      // Cleanup listener on component unmount
      return () => {
        document.removeEventListener("visibilitychange", handleVisibilityChange);
      };

  }, []);

  const autoLogout=()=>{
    props.firebase

    .doSignOut()

    .then((signoutRes) => {

      localStorage.setItem('encryptedString', 'null')

      localStorage.setItem('userId', 'null')
      window.location.href='login'
      // localStorage.setItem('isFreshLogin', 'true')

      return;

    })
  }
  const handleOnActive = (event) => {
    // console.log('user is active', event);
    // console.log('time remaining', getRemainingTime());
  };

  const handleOnAction = (event) => {
    // console.log('user did something', event);
  };

  const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    timeout: 4 * 60 * 60 * 1000,
    // timeout: 3 * 60 * 1000,
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    onAction: handleOnAction,
    debounce: 500,
  });
  return (
    <Fragment>
      <ReactNotification />
      <Routes firebase={props.firebase} />
    </Fragment>
  );
};
export default withAuthentication(App);
