import React from "react";
import { Doughnut } from 'react-chartjs-2';
import { Row, Col } from 'react-bootstrap';
import s from './style.scss';
const overlayStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    textAlign: 'center',
    fontSize: '16px',
    color: '#000',
  };
const DoughnutChart = ({ data, subtitle }) => {
    // Chart options including the custom plugin
    const options = {
      responsive: true, // Set the chart to be responsive
      maintainAspectRatio: false, // Allow the chart to stretch as needed
      cutout: '60%', // Adjust the width of the doughnut
      plugins: {
        legend: {
          display: false,
          position: 'bottom', // Place the legend at the bottom
          labels: {
            usePointStyle: true,
            pointStyle: 'circle',
            padding: 10, // Optional: Add some padding between legend items
          },
        },
      },
    };

    return (
      <div style={{ height: '300px' }}>
        <Doughnut data={data} options={options} />
        {/* Render the overlay div for the subtitle */}
        <div style={overlayStyle}>{subtitle}</div>
      </div>
    );
  };
const ProjConcentrationAnalytics=()=>{
    const labelsData=[
        {
            label:'General',
            color:'#97B4FF',
            data:31,
        },
        {
            label:'Commercial',
            color:'#05B5FE',
            data:9,
        },
        {
            label:'Residential',
            color:'#DBBFFF',
            data:15,
        },
        {
            label:'Interior Design ',
            color:'#FFB9A2',
            data:10,
        },
        {
            label:'Green Design',
            color:'#8CFAFA',
            data:5,
        },
        {
            label:'Landscape Architect',
            color:'#FFE2C7',
            data:7,
        },
        {
            label:'Urban Designer',
            color:'#E0E0E0',
            data:10,
        },
        {
            label:'Industrial Architect',
            color:'#A56EFF',
            data:6,
        },
        {
            label:'Other',
            color:'#FF5F00',
            data:7,
        },
    ]
    const chartData1 = {
        labels: labelsData.map(l=>l.label),
        // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
        datasets: [
          {
            data: labelsData.map(l =>l.data),
            // you can set indiviual colors for each bar
            backgroundColor: labelsData.map(l => l.color),
            borderWidth: 1,
          },
        ],
    }
    return (
        <div className={s.statisticsCard}>
              <p className={s.headingText}>Project Concentration</p>
              <DoughnutChart
                data={chartData1}
                subtitle="31% Commercial"
              />

            <Row className="mt-1">
                {
                    labelsData.map((item, index) => (
                      <Col key={index} xs={4} className='d-flex my-1'>
                        <div className="" style={{ backgroundColor: item.color, height: '9px', width: '9px', borderRadius: '50%', marginRight:'4px',marginTop:'3px'}} />
                        <span className={`${s.smallText}`}>{item.label}</span>
                      </Col>
                    ))
                }
            </Row>
            </div>
    )
}
export default ProjConcentrationAnalytics;