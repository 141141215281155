import React, { useState, useEffect } from 'react';
import Header from '../../../Header';
import { Row, Col } from 'react-bootstrap';
import SearchInput from '../../../SearchInput';
import s from './style.scss';
import ReactTable from 'react-table';
import 'react-table/react-table.css';
import COLUMNS from './COLUMNS';
import { withAuthentication } from '../../../../auth/session';
import TableWithCustomPagination from './TableWithPagination';
const FirmDetails = ({ firebase }) => {
  // State to hold the index of the currently hovered row
  const [hoveredRowIndex, setHoveredRowIndex] = useState(null);
  const [tableLoading, setTableLoading] = useState(false);

  // State to hold original and filtered data
  const [data, setData] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  useEffect(() => {
    setTableLoading(true);
    firebase
      .getFirmsList()
      .then((res) => {
        setData(res);
        setOriginalData(res); // Store original data
        setTableLoading(false);
      })
      .catch((error) => {
        console.log('error ===> ', error);
      });
  }, [firebase]);

  // Function to handle row props
  const getTrProps = (_, rowInfo) => {
    return {
      onMouseEnter: () => setHoveredRowIndex(rowInfo.index),
      onMouseLeave: () => setHoveredRowIndex(null),
    };
  };

  // Function to handle search
  const handleSearch = (searchValue) => {
    if (searchValue) {
      const filteredData = originalData.filter((item) =>
        item.firmName.toLowerCase().includes(searchValue.toLowerCase()),
      );
      setData(filteredData);
    } else {
      setData(originalData); // Reset to original data
    }
  };

  return (
    <>
      <Header title="Edge Portal" edgePortal />
      <div className={s.wrapper}>
        <Row style={{ marginBottom: '10px' }}>
          <Col>
            <p className={`${s.title}`}>Firms</p>
          </Col>
          <Col xs={12} sm={6} md={3} lg={2}>
            <SearchInput searchFirm={handleSearch} />
          </Col>
        </Row>
        <ReactTable
          data={data}
          columns={COLUMNS(hoveredRowIndex)}
          expanded={false}
          className={s.ReactTable}
          getTrProps={getTrProps}
          showPagination={true}
          showPageJump={true}
          // page={1}
          // showPageSizeOptions={true}
          // pageSizeOptions={[5, 10, 20, 25, 50, 100]}
          loading={tableLoading}
        />
      </div>
    </>
  );
};

export default withAuthentication(FirmDetails);
