import React from 'react';
import s from './style.scss';
import MagnifyingGlass from '../../assets/MagnifyingGlass.svg'

const SearchInput = ({ array, getFilteredData, searchTarget, searchFirm }) => {
  const formatDate = (date) => {
    //console.log('date == = = = =  >', date ? date.toDate() : date);
    const dateObj = new Date(date);
    //console.log('dateObj =============>', dateObj);
    var year = dateObj.getFullYear();
    var month = (1 + dateObj.getMonth()).toString();
    month = month.length > 1 ? month : '0' + month;

    var day = dateObj.getDate().toString();
    day = day.length > 1 ? day : '0' + day;

    return month + '/' + day + '/' + year;
  };

  const handleSearch = async (e) => {
    const search = e.target.value;
    const isEdgePortal = localStorage.getItem('edgePortal')
    if(isEdgePortal) {
      searchFirm(search);
    }
    var filtered = [];
    setTimeout(() => {
      if (array) {
        filtered = array.filter((data) => {
          return (
            (data['name'] &&
              data['name'].toLowerCase().indexOf(search.toLowerCase()) !==
                -1) ||
            (data['email'] &&
              data['email'].toLowerCase().indexOf(search.toLowerCase()) !==
                -1) ||
            (data['firstName'] &&
              data['firstName'].toLowerCase().indexOf(search.toLowerCase()) !==
                -1) ||
            (data['lastName'] &&
              data['lastName'].toLowerCase().indexOf(search.toLowerCase()) !==
                -1) ||
            (data['projectName'] &&
              data['projectName']
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            (data['id'] &&
              data['id']
                .toString()
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            (data['dateUploaded'] &&
              formatDate(data['dateUploaded'])
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            (data['primarySealPactSigner'] &&
              data['primarySealPactSigner']
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            (data['sealPacterType'] &&
              data['sealPacterType']
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            (data['projectNumber'] &&
              data['projectNumber']
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            (data['projectAddress'] &&
              data['projectAddress']
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            (data['projectAddress'] &&
              data['projectAddress']
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1) ||
            (data['dateSealPacted'] &&
              formatDate(data['dateSealPacted'])
                .toLowerCase()
                .indexOf(search.toLowerCase()) !== -1)
          );
        });
        getFilteredData(filtered);
      }
    }, 1000);
  };
  return (
    <div className={s.root}>
      {/*<i className={`fa fa-search ${s.icon}`} aria-hidden="true"></i>*/}
      <img className={s.icon} src={MagnifyingGlass} align={'MagnifyingGlass'}/>
      <input
        className={s.input}
        onChange={handleSearch}
        placeholder="Search"
        type="text"
        height="20"
      />
    </div>
  );
};

export default SearchInput;
