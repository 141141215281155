import React from 'react';
import ColHeading from './ColHeading';
import s from './style.scss';
import { Link } from 'react-router-dom';

const cellStyle = { border: 0, justify_content: 'center', display: 'flex' };

const COLUMNS = [
  {
    Header: () => <ColHeading heading="Firm Name" />,
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    accessor: 'firmName',
    style: cellStyle,
    id: 'firmName',
  },
  {
    Header: () => <ColHeading heading="Yearly Goal Completion" />,
    Cell: (props) => (
      <div className={s.tblCell}>
        <div> {props.value} Complete</div>
        <Link to="/statistics">
          <span className={s.statsText}>View Statistics</span>
        </Link>
      </div>
    ),
    accessor: 'yearlyGoalCompletion',
    style: cellStyle,
    id: 'yearlyGoalCompletion',
  },
  {
    Header: () => <ColHeading heading="No. of Design Professionals" />,
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    accessor: 'designProfessionals',
    style: cellStyle,
    id: 'designProfessionals',
  },
  {
    Header: () => <ColHeading heading="No. of Documents" />,
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    accessor: 'documentsCount',
    style: cellStyle,
    id: 'documentsCount',
  },
];
export default COLUMNS;
