import React, { Fragment, useState } from 'react';
import { Form, Button, Container, Row, Col } from 'react-bootstrap';
import s from './style.scss';
import LogoBrand from '../../LogoBrand';
import { FORGOTPASSWORDVALIDATIONSCHEMA } from '../../../config/FormSchemas';
import { Formik } from 'formik';
import MaskedInput from '../../AppComponents/MaskedInput';
import { withFirebase } from '../../../auth/firebase';
import querystring from 'query-string';
import { store } from 'react-notifications-component';
import { NOTIFICATION_PROPS } from '../../../utils/constants';

const ForgotPassword = (props) => {
  const [codeSent, setcodeSent] = useState(false);
  const [email, setEmail] = useState('');
  const {
    history: { push },
  } = props;
  const resetPassword = () => {
    console.log('reset password')
    props.firebase.doPasswordReset(email)
    setcodeSent(!codeSent);
  };
  const { oobCode } = querystring.parse(props.location.search);
  {
    console.log('oobCode ============> ', oobCode);
  }
  return (
    <Formik
      initialErrors={{
        email: 'Email Not Valid',
      }}
      initialValues={{
        email: '',
        newPassword: '',
        verifyPassword: '',
      }}
      validationSchema={FORGOTPASSWORDVALIDATIONSCHEMA}
      onSubmit={({ newPassword }) => {
        try {
          props.firebase
            .doConfirmPasswordReset(oobCode, newPassword)
            .then((res) => {
              push('/login');
            }).catch((err)=>{
              console.log('error:',err);
              store.addNotification({
                title: 'Error',
                message: err.message,
                type: 'danger',
                ...NOTIFICATION_PROPS,
              });
            })
        } catch (e) {
          store.addNotification({
            title: 'Error',
            message: 'All fields are required',
            type: 'danger',
            ...NOTIFICATION_PROPS,
          });
          // console.log('e', e);
        }
      }}
    >
      {(formikProps) => {
        const { errors, values, handleSubmit } = formikProps;
        setEmail(values.email);
        const makeDisable = (array) => {
          var errorArray = array.filter((key) =>
            Object.keys(errors).includes(key),
          );
          const hasErrors = errorArray.length > 0;
          return hasErrors;
        };
        return (
          <Container>
            <div className={s.root}>
              <div className={s.headerLogo}>
                <LogoBrand imgSize={48} className={s.logoStyle} {...props} />
              </div>
              <div className={s.content}>
                <Form onSubmit={handleSubmit}>
                  <Row>
                    <Col
                      xs={{ span: 10, offset: 1 }}
                      sm={{ span: 8, offset: 2 }}
                      md={{ span: 6, offset: 3 }}
                      lg={{ span: 4, offset: 4 }}
                    >
                      {!codeSent && !oobCode && (
                        <Fragment>
                          <div className={s.title}>
                            <p>Forgot Password</p>
                          </div>
                          <MaskedInput
                            name="email"
                            style={s.formLabel}
                            formikProps={formikProps}
                            label="Please enter your Email Address, we’ll help you reset it
                           and get back on track."
                          />
                          <Button
                            variant="primary"
                            disabled={email === '' || makeDisable(['email'])}
                            onClick={resetPassword}
                            block
                          >
                            Reset password
                          </Button>
                          <Button
                            variant="outline-secondary"
                            block
                            onClick={() => {
                              push('/login');
                            }}
                          >
                            Back to Login
                          </Button>
                        </Fragment>
                      )}
                      {codeSent && (
                        <p>
                          We've sent you an email with instructions to reset
                          your password.
                        </p>
                      )}
                      {oobCode && (
                        <Fragment>
                          <MaskedInput
                            name="newPassword"
                            formikProps={formikProps}
                            label="New password"
                            type="password"
                            autoComplete="current-password"
                          />
                          <Fragment>
                            <MaskedInput
                              name="verifyPassword"
                              formikProps={formikProps}
                              label="Verify password"
                              type="password"
                              autoComplete="off"
                            />
                          </Fragment>
                          <Button
                            variant="primary"
                            type="submit"
                            disabled={makeDisable([
                              'verifyPassword',
                              'newPassword',
                            ])}
                            block
                          >
                            Set password
                          </Button>
                          <Button
                            variant="outline-secondary"
                            onClick={resetPassword}
                            block
                          >
                            Back
                          </Button>
                        </Fragment>
                      )}
                    </Col>
                  </Row>
                </Form>
              </div>
            </div>
          </Container>
        );
      }}
    </Formik>
  );
};

export default withFirebase(ForgotPassword);
