import React from 'react';
import ColHeading from './ColHeading';
import s from './style.scss';
import { Link } from 'react-router-dom';
import ArrowRight from '../../../../assets/arrow-right.png';

const cellStyle = { border: 0, justify_content: 'center', display: 'flex' };

const COLUMNS = (hoveredRowIndex) => [
  {
    Header: () => <ColHeading heading="Firm Name" />,
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    accessor: 'firmName',
    // resizable: false,
    style: cellStyle,
    id: 'firmName',
  },
  {
    Header: () => <ColHeading heading="Yearly Goal Completion" />,
    // resizable: false,
    Cell: (props) => (
      <div className={s.tblCell}>
        <div> {props.value} Complete</div>
        <Link to="/statistics">
          <span className={s.statsText}>View Statistics</span>
        </Link>
      </div>
    ),
    accessor: 'yearlyGoalCompletion',
    style: cellStyle,
    id: 'yearlyGoalCompletion',
  },
  {
    Header: () => <ColHeading heading="No. of Design Professionals" />,
    // resizable: false,
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    accessor: 'designProfessionals',
    style: cellStyle,
    id: 'designProfessionals',
  },
  {
    Header: () => <ColHeading heading="No. of Documents" />,
    // resizable: false,
    Cell: (props) => <div className={s.tblCell}> {props.value}</div>,
    accessor: 'documentsCount',
    style: cellStyle,
    id: 'documentsCount',
  },
  {
    // New column without a header
    Header: '',
    // resizable: false,
    id: 'hoverLinkColumn',
    Cell: ({ index }) => {
      // Check if the current cell is in the hovered row
      if (index === hoveredRowIndex) {
        // Return the clickable link
        return (
          <div
            className={s.tblCell}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link to="/firmAnalytics">
              <span className={s.statsText}>View Firm Analytics</span>
            </Link>
            <img
              src={ArrowRight}
              style={{ width: '20px', height: '20px', marginLeft: '5px' }}
            />
          </div>
        );
      } else {
        // Otherwise, return an empty cell
        return null;
      }
    },
    style: cellStyle,
  },
  {
    // New column without a header
    Header: '',
    // resizable: false,
    id: 'hoverLinkColumn2',
    Cell: ({ index }) => {
      // Check if the current cell is in the hovered row
      if (index === hoveredRowIndex) {
        // Return the clickable link
        return (
          <div
            className={s.tblCell}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Link to="/designProfessionals">
              <span className={s.statsText}>View Design Professionals</span>
            </Link>
            <img
              src={ArrowRight}
              style={{ width: '20px', height: '20px', marginLeft: '5px' }}
            />
          </div>
        );
      } else {
        // Otherwise, return an empty cell
        return null;
      }
    },
    style: cellStyle,
  },
];
export default COLUMNS;
