import React from 'react';
import Header from '../../../Header';
import CanvasJSReact from '@canvasjs/react-charts';
import s from './style.scss';
import { Row, Col } from 'react-bootstrap';
//var CanvasJSReact = require('@canvasjs/react-charts');
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import CaretLeft from '../../../../assets/CaretLeft.svg';
import { Link } from 'react-router-dom';
import ClientTypeAnalytics from './ClientTypeAnalytics'
import ProjConcentrationAnalytics from './ProjConcentrationAnalytics'
import ProjDisciplineAnalytics from './ProjDisciplineAnalytics'
import ProjFeeAnalytics from './ProjFeeAnalytics'
ChartJS.register(ArcElement, Tooltip, Legend);

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const overlayStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  textAlign: 'center',
  fontSize: '16px',
  color: '#000',
};


const DoughnutChart = ({ data, subtitle }) => {
  // Chart options including the custom plugin
  const options = {
    responsive: true, // Set the chart to be responsive
    maintainAspectRatio: false, // Allow the chart to stretch as needed
    cutout: '70%', // Adjust the width of the doughnut
    plugins: {
      legend: {
        display: true,
        position: 'bottom', // Place the legend at the bottom
        labels: {
          usePointStyle: true,
          pointStyle: 'circle',
          padding: 10, // Optional: Add some padding between legend items
        },
      },
    },
  };

  return (
    <div style={{ height: '300px' }}>
      <Doughnut data={data} options={options} />
      {/* Render the overlay div for the subtitle */}
      <div style={overlayStyle}>{subtitle}</div>
    </div>
  );
};

const FirmAnalytics = () => {
  // const liabilityChartOptions = {
  //   animationEnabled: true,
  //   title: {
  //     text: 'Liability Credit',
  //     horizontalAlign: 'left',
  //     fontFamily: 'sans-serif',
  //     fontSize: 16,
  //     fontWeight: 600,
  //     fontColor: '#0F172A',
  //   },
  //   subtitles: [
  //     {
  //       text: '36% Liability Credit',
  //       verticalAlign: 'center',
  //       fontFamily: 'sans-serif',
  //       fontSize: 16,
  //       fontWeight: 600,
  //       fontColor: '#0F172A',
  //       dockInsidePlotArea: true,
  //     },
  //   ],
  //   data: [
  //     {
  //       type: 'doughnut',
  //       showInLegend: true,
  //       // indexLabel: '{name}: {y}',
  //       // yValueFormatString: "#,###'%'",
  //       startAngle: 270,
  //       dataPoints: [
  //         { name: '3600$ Liability Credit Achieved', y: 36, color: '#477BFF' },
  //         { name: '100,00$ Total Liability', y: 64, color: '#C8D7FF' },
  //       ],
  //     },
  //   ],
  // };

  // const EducationalChartOptions = {
  //   animationEnabled: true,
  //   title: {
  //     text: 'Educational Credit',
  //     horizontalAlign: 'left',
  //     fontFamily: 'sans-serif',
  //     fontSize: 16,
  //     fontWeight: 600,
  //     fontColor: '#0F172A',
  //   },
  //   subtitles: [
  //     {
  //       text: '66% Educational Credit',
  //       verticalAlign: 'center',
  //       fontFamily: 'sans-serif',
  //       fontSize: 16,
  //       fontWeight: 600,
  //       fontColor: '#0F172A',
  //       dockInsidePlotArea: true,
  //     },
  //   ],
  //   data: [
  //     {
  //       type: 'doughnut',
  //       showInLegend: true,
  //       // indexLabel: '{name}: {y}',
  //       // yValueFormatString: "#,###'%'",
  //       startAngle: 270,
  //       dataPoints: [
  //         { name: '4 Seminars Attended', y: 66, color: '#477BFF' },
  //         { name: '6 Total Seminars', y: 34, color: '#C8D7FF' },
  //       ],
  //     },
  //   ],
  // };

  // const SealpactChartOptions = {
  //   animationEnabled: true,
  //   title: {
  //     text: 'SealPact Credit',
  //     horizontalAlign: 'left',
  //     fontFamily: 'sans-serif',
  //     fontSize: 16,
  //     fontWeight: 600,
  //     fontColor: '#0F172A',
  //   },
  //   subtitles: [
  //     {
  //       text: '47% SealPact Credit',
  //       verticalAlign: 'center',
  //       fontFamily: 'sans-serif',
  //       fontSize: 16,
  //       fontWeight: 600,
  //       fontColor: '#0F172A',
  //       dockInsidePlotArea: true,
  //     },
  //   ],
  //   data: [
  //     {
  //       type: 'doughnut',
  //       showInLegend: true,
  //       // indexLabel: '{name}: {y}',
  //       // yValueFormatString: "#,###'%'",
  //       startAngle: 270,
  //       dataPoints: [
  //         { name: '$4700 Revenue Achieved', y: 47, color: '#477BFF' },
  //         { name: '$100,00 Total Revenue', y: 53, color: '#C8D7FF' },
  //       ],
  //     },
  //   ],
  //   legend: {
  //     fontSize: 12,
  //     fontWeight: 400,
  //     fontColor: '#0F172A9C',
  //   },
  // };

  // Define data for multiple charts
  const chartData1 = {
    labels: ['$3600 Liability Credit Achieved', '$100,00 Total Liability'],
    // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
    datasets: [
      {
        data: [36, 64],
        // you can set indiviual colors for each bar
        backgroundColor: ['#477BFF', '#C8D7FF'],
        borderWidth: 1,
      },
    ],
  };

  const chartData2 = {
    labels: ['4 Seminars Attended', '6 Total Seminars'],
    datasets: [
      {
        data: [66, 34],
        backgroundColor: ['#477BFF', '#C8D7FF'],
        borderWidth: 1,
      },
    ],
  };

  const chartData3 = {
    labels: ['$4700 Revenue Achieved', '$100,00 Total Revenue'],
    datasets: [
      {
        data: [47, 53],
        backgroundColor: ['#477BFF', '#C8D7FF'],
        borderWidth: 1,
      },
    ],
  };

  // const data = {
  //   labels: ['Red', 'Orange'],
  //   // datasets is an array of objects where each object represents a set of data to display corresponding to the labels above. for brevity, we'll keep it at one object
  //   datasets: [
  //     {
  //       data: [36, 64],
  //       // you can set indiviual colors for each bar
  //       backgroundColor: ['#477BFF', '#C8D7FF'],
  //       borderWidth: 1,
  //     },
  //   ],
  // };

  // Define a custom plugin to draw the subtitle in the center of the doughnut chart
  // const subtitlePlugin = {
  //   id: 'subtitlePlugin',
  //   afterDatasetsDraw(chart, args, options) {
  //     const { ctx } = chart;
  //     ctx.save();

  //     // Define the subtitle text
  //     const subtitle = 'Subtitle';

  //     // Get the center coordinates of the doughnut chart
  //     const centerX = chart.getDatasetMeta(0).data[0].x;
  //     const centerY = chart.getDatasetMeta(0).data[0].y;

  //     // Define the text properties
  //     ctx.textAlign = 'center';
  //     ctx.textBaseline = 'middle';
  //     ctx.font = '16px Arial';
  //     ctx.fillStyle = '#000'; // Color of the text

  //     // Draw the subtitle
  //     ctx.fillText(subtitle, centerX, centerY + 20); // Adjust position as needed

  //     ctx.restore();
  //   },
  // };

  // Chart options including the plugin
  // const options = {
  //   cutout: '70%', // Increase the cutout size to reduce the width of the doughnut
  //   plugins: {
  //     legend: {
  //       display: true,
  //       position: 'bottom', // Place the legend at the bottom
  //       labels: {
  //         // Customize the legend labels
  //         usePointStyle: true,
  //         pointStyle: 'circle', // Set the point style to circle
  //         padding: 10, // Optional: Add some padding between legend items
  //       },
  //     },
  //     subtitlePlugin: true, // Enable the subtitle plugin
  //   },
  // };

  // Add the plugin to the chart
  // ChartJS.register(subtitlePlugin);
  // Style the overlay container

  return (
    <>
      <Header title="Edge Portal" edgePortal />
      <div className={s.wrapper}>
        <Row>
          <Link to="/firmDetails" style={{ textDecoration: 'none' }}>
            <div className={s.backContainer}>
              <img src={CaretLeft} />
              <span className={s.headingText}>
                Cianci Engineering’s Yearly Goal Completion Insights
              </span>
            </div>
          </Link>
        </Row>
        <div className={`my-3 ${s.card}`}>
          <Row>
            <Col xs={12} sm={12} md={12} lg={12} className='mb-3'>
              <span className={s.headingText}>Statistics</span>
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <p className={`mb-1 ${s.headingText}`}>487</p>
              <span className={s.planText}>Projects Need Quality Controls</span>
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <p className={`mb-1 ${s.headingText}`}>15</p>
              <span className={s.planText}>Projects Need Independent Review</span>
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <p className={`mb-1 ${s.headingText}`}>368</p>
              <span className={s.planText}>Projects Need Shop Drawings</span>
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <p className={`mb-1 ${s.headingText}`}>157</p>
              <span className={s.planText}>Projects Need Construction Administration</span>
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <p className={`mb-1 ${s.headingText}`}>245</p>
              <span className={s.planText}>Projects Have Waiver Consequential Damages</span>
            </Col>
            <Col xs={6} sm={6} md={4} lg={2}>
              <p className={`mb-1 ${s.headingText}`}>$6757</p>
              <span className={s.planText}>Average Limit of Liability (Amount)</span>
            </Col>

          </Row>
        </div>
        <Row>
          <Col xs={12} sm={6} md={6} lg={4}>
            <ClientTypeAnalytics/>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4}>
            <ProjConcentrationAnalytics/>
          </Col>
          <Col xs={12} sm={6} md={6} lg={4}>
            <ProjDisciplineAnalytics/>
          </Col>
        </Row>
        <Row className='mt-3'>
          <Col xs={12} sm={6} md={6} lg={4}>
            <ProjFeeAnalytics/>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default FirmAnalytics;
